import React from 'react';

import withBg from '../helpers/withBg';
import Layout from '../components/layout';
import SubscribeForm from '../components/SubscribeForm';
import SEO from '../components/seo';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../components/Sidebar/SidebarStaticPage';
import RedMorelyImg from '../components/Image/RedMorelyImg';
import './styles.css';

const data = {
  title: 'About US',
  seoTitle: 'About US | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class aboutUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber, ready } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Travelopod&nbsp;&nbsp;Where can we take you?</h1>
                <p>
                  Travelopod is comprised of global travel specialists, offering
                  comprehensive travel solutions with competitive rates and
                  superior service. Be it a family holiday, an african safari, a
                  cruise, or just flight and hotel bookings, Travelopod is a
                  preferred choice of millions of highly satisfied customers.
                </p>
                <p>
                  With offices in the US, Canada, Australia and India,
                  Travelopod has made its niche providing highly discounted air
                  tickets for clients traveling from the US to destinations
                  around the globe. Our team works hard so that our customers
                  don’t have to. We've made it easy to match your unique flight
                  needs with the best travel offers possible. We are proud of
                  our hybrid model that allows our customers to get deep
                  discounted airfare.
                </p>
                <RedMorelyImg
                  alt="red morley he     
                  witt 632745 unsplash"
                />
                <h2>Our Mission</h2>
                <p>
                  Together, we have aspired to create a business that is focused
                  on outstanding service and competitive fares. <br />
                  "Creating & cherishing memories through travel" is our
                  mandate.
                  <br />
                  For us, travel is not about airplanes, cruises, hotels etc.
                  Travel is about the memories one brings back home. Visiting
                  family, experiencing new destinations, meeting new people and
                  getting back together with folks is what travel is all about.
                  Our 5-star ratings and reviews are a testimony to our
                  commitment. in fact, 92% of our customers would recommend us.
                </p>
                <h2>Our Values</h2>
                <ul>
                  <li>
                    We provide state of the art communication technology to
                    service our International clients
                  </li>
                  <li>Our team works hard so our customers don’t have to</li>
                  <li>
                    We've made it easy to match our client’s unique needs with
                    the best travel offers in the market
                  </li>
                  <li>
                    We are proud of our hybrid model that allows our customers
                    to get deep discounted airfar
                  </li>
                  <li>
                    We work on the foundation of mutual trust, individual
                    contribution, and very high quality leadership
                  </li>
                  <li>We have a culture of employee ownership & leadership</li>
                  <li>
                    We are committed to excellence, honesty, respect & integrity
                  </li>
                </ul>
                <h2>Our Process</h2>
                <p>
                  Our hybrid business model offers a convenient online booking
                  capability along with a 24/7 personalized travel booking
                  experience assisted by a trained and certified travel
                  counsellor. We offer one-on-one service to provide our clients
                  with low cost tickets efficiently and in a user-friendly
                  environment.
                </p>
                <p>
                  Our partnerships with consolidators and airlines allow us to
                  pass on huge discounts and cut travel costs without
                  sacrificing travel comfort. Our clients receive the best value
                  from one-on-one service by our live travel counsellors. Our
                  clients pay for a great airline seat for about 30-45% less
                  than they will pay anywhere online! Wondering how are we able
                  to offer deep discounts on flight bookings and why we ask to
                  call us directly? Let us explain! whether it be the economy or
                  business class fares, some of the cheapest airline contracted
                  deals are not allowed by airlines for online publishing, and
                  those can only be sold in offline mode via phone. these deals
                  offer deep discounts - sometimes cutting prices down by 30 to
                  45% than online fares and even more for peak season travel.{' '}
                </p>
                <p>
                  Italy, Alaska, or whatever is on your bucket list – we can
                  help you get there! along with offering best airfares, we also
                  provide tailor-made journeys, designed to your interests,
                  tastes and budget, throughout Asia, Europe, Africa, the polar
                  regions and Australasia. We can also offer expert advice on
                  cruises, hotel and local tour bookings. Custom trips to India,
                  Southeast Asia, African safari, Italy, Greece, Scandinavian
                  countries along with Alaska, and river cruises are our most
                  popular international journeys. all inclusive trips to Cancun,
                  Hawaii, Caribbean and other islands are our specialty for the
                  North America region. Our destination experts are explorers
                  with experience and we are committed to delivering memorable
                  tours for families, couples, friends travelers.
                </p>
              </div>
              <SubscribeForm />
            </div>
            <SidebarStaticPage phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(aboutUsPage);
